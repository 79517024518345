import styled from 'styled-components'

import PageTitle from '../../components/PageTitle'
import SnowCardImg from '../../assets/images/snow-card.png'
import ItemsList from '../../components/ItemsList'

const ITEMS: string[] = [
  'Druskos barstymas',
  'Sniego valymas',
  'Mechanizuotas teritorijos barstymas druska ir druskos-smėlio mišiniu',
]

const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;

  @media ${({ theme }) => theme.queries.mobile} {
    grid-template-columns: 1fr;
  }
`

const RightContentContainer = styled.div`
  display: grid;
  align-content: start;
  row-gap: 1.5rem;
`

const SnowCard = styled.img.attrs({
  src: SnowCardImg,
  alt: 'snow-cleaning-card',
})`
  border-radius: 0.25rem;
  overflow: hidden;
  object-fit: cover;
  width: 100%;
  height: auto;
`

const Description = styled.p`
  color: ${({ theme }) => theme.colors.color3};
  font-weight: 700;

  i {
    font-style: italic;
  }
`

const InfoContainer = styled.div`
  padding-top: 1.5rem;
`

const Info = styled.p`
  color: ${({ theme }) => theme.colors.color3};
  font-weight: 700;
  font-size: 1.25rem;
  text-align: center;
`

const SnowCleaning = () => {
  return (
    <>
      <PageTitle title="Sniego valymas" />

      <ContentContainer>
        <SnowCard />

        <RightContentContainer>
          <Description>
            Mūsų įmonė jau eilę metų užsiima sniego valymu Kaune. Šiam tikslui turime visą reikiamą techniką.
            Mechanizuotas sniego valymas atliekamas operatyviai ir profesionaliai.{' '}
            <i>Pasirūpinkite savo lauko teritorija dar neprasidėjus žiemai.</i> Palikite rūpesčius mums!
          </Description>

          <ItemsList items={ITEMS} />
        </RightContentContainer>
      </ContentContainer>

      <InfoContainer>
        <Info>
          Siūlome Jums pasirašyti sezoninę teritorijos valymo sutartį ir mes atliksime teritorijos valymą kiekvieną
          kartą sniegui iškritus!
        </Info>
      </InfoContainer>
    </>
  )
}

export default SnowCleaning
