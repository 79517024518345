import { weatherApi } from './weatherApi'

export interface WeatherData {
  [city: string]: {
    city: string
    temp: number
  }
}

export interface City {
  name: string
  lat: number
  lon: number
}

export const cacheImages = async (srcs: string[]) => {
  const promises = srcs.map((src) => {
    return new Promise((resolve, reject) => {
      const img = new Image()

      img.src = src
      img.onload = resolve
      img.onerror = reject
    })
  })

  await Promise.all(promises)
}

export const fetchWeatherData = async (cities: City[]) => {
  const promises = cities.map((city) => {
    return weatherApi('/weather', { params: { lat: city.lat, lon: city.lon } })
  })

  const responses = await Promise.all(promises)

  const weatherData = responses.reduce<WeatherData>((obj, response, index) => {
    const city = cities[index].name

    obj[city] = {
      city,
      temp: Math.round(response.data.main.temp),
    }

    return obj
  }, {})

  return weatherData
}
