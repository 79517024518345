import styled from 'styled-components'
import { useState } from 'react'
import emailjs from '@emailjs/browser'

import PageTitle from '../../components/PageTitle'
import GetInTouch from '../../components/GetInTouch'
import ContactsForm, { FormData } from '../../components/ContactsForm'

const GetInTouchContainer = styled.div`
  padding-top: 1.5rem;
`

const InfoContainer = styled.div`
  padding: 1.5rem 0;
`

const Info = styled.p`
  color: ${({ theme }) => theme.colors.color3};
  font-weight: 700;
  font-size: 1.25rem;
  text-align: center;
`

const ContactsFormContainer = styled.div`
  width: 28rem;
  margin: 0 auto;
  max-width: 100%;

  @media ${({ theme }) => theme.queries.mobile} {
    width: 100%;
  }
`

const Contacts = () => {
  const [loading, setLoading] = useState<boolean>(false)

  const handleSubmit = async (data: FormData) => {
    try {
      setLoading(true)

      await emailjs.send(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_TEMPLATE_ID, data)

      alert('Jūsų užklausa gauta! Susisieksime artimiausiu metu.')

      setLoading(false)

      return { success: true }
    } catch (error) {
      console.error(error)

      alert('Atsiprašome, įvyko klaida. Prašome bandyti dar kartą arba kreiptis nurodytais kontaktais viršuje.')

      window.scroll({ top: 0, behavior: 'smooth' })

      setLoading(false)

      return { success: false }
    }
  }

  return (
    <>
      <PageTitle title="Kontaktai" description="Kaunas" />

      <GetInTouchContainer>
        <GetInTouch />
      </GetInTouchContainer>

      <InfoContainer>
        <Info>Pateikite žemiau savo kontaktus ir mes su Jumis susisieksime artimiausiu metu!</Info>
      </InfoContainer>

      <ContactsFormContainer>
        <ContactsForm loading={loading} onSubmit={handleSubmit} />
      </ContactsFormContainer>
    </>
  )
}

export default Contacts
